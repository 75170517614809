<template lang="pug">
.app
  van-nav-bar(
    title="活 动"
    @click-left="onClickLeft"
    left-text="返回"
    left-arrow)
  div
    div(class="input" style="background-color: #FFFFFF;display: flex;align-items: center;justify-content: space-around;font-size: 16px;    box-sizing: border-box;box-shadow: 0 0.5em 2em -0.7em rgb(64 90 163 / 15%);" id='ln')
      van-radio-group(v-model="radio" direction="horizontal")
        van-radio(name="1") 中 文
        van-radio(name="2") English
    div(class="content" style="width: 100%;")
      img(:src="data.url != '' ? require('@/assets/ww/' + data.url) : require('@/assets/images/unknown.png')"  style="width:100%")
    van-cell-group
      van-cell(:title="data.name" size="large")
        template(#title)
          span(style="font-weight: 600; font-size: 18px;") {{ data.name }}
      van-cell(title="活动时间" :value="$u.formatToDate(data.time)")
      van-cell(
        :value="title"
        is-link
        name="picker"
        title="活动地点"
        @click="showAddress"
      )
      van-cell(title="人数限制" :value="runshu")
      van-cell(title="活动场地")
        template(#label)
          span(style="font-weight: 400; font-size: 18px;") {{ address }}

    div(id="qrcode" style="display: table;margin: 20px auto;" v-if="status=== 1")

    van-button(type="info" style="width:100%" @click="save" :disabled="ttdis") {{ tt }}
    van-button(type="danger" style="width:100%" @click="cal"   v-if="status=== 1") 取消报名

  van-popup(v-model:show="showPicker" position="bottom")
    van-picker(
      value-key="name"
      :columns="data2"
       show-toolbar
      @confirm="onConfirm"
      @cancel="showPicker = false"
      )

</template>

<script>
import QRCode from 'qrcodejs2'
import { Toast } from 'vant'

export default {
  data: () => ({
    tt: '报 名',
    ttdis: false,
    radio: '1',
    url: '',
    status: 0,
    count: 0,
    sumCount: 0,
    show: false,
    value: '',
    title: '',
    address: '',
    showPicker: false,
    columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
    showPopover: false,
    schema_type: [
      { id: 'T01', name: '普通表单' },
      // { id: "T02", name: "流程表单" },
      { id: 'T03', name: '报表' },
    ],
    addFormDialog: false,
    addAppDialog: false,
    addFormImageDialog: false,
    addAppImageDialog: false,
    addFormObj: {
      formId: '',
      formName: '',
      formDesc: '',
      formType: 'T01',
      formAppID: '',
      formPicture: 'form1.png',
    },
    addAppObj: {
      formId: '',
      formName: '',
      formDesc: '',
      formPicture: 'app1.png',
    },
    teams: [],
    defaultIndex: 0,
    data: {
      url: '',
    },
    data2: [],
  }),
  computed: {
    runshu () {
      return this.count + '/' + this.sumCount
    },
    team () {
      return this.$s.getters.team
    },
    profile () {
      return this.$s.getters.profile
    },
    role () {
      return this.$s.getters.role
    },
  },
  watch: {
    $route: function (to, from) {
      console.log('change route')
      this.initData()
    },
    team: function (team) {
      console.log('change team to', team.name)
      this.initData()
    },
  },
  mounted () {
    console.log('index mounted')
  },
  created () {
    const id = this.$route.params.id
    this.getAct(id)
  },
  methods: {
    showAddress () {
      if (this.ttdis === false) {
        this.showPicker = true
      }
    },
    cal () {
      if (this.status !== 1) {
        Toast.fail('当前活动不是报名状态')
        return
      }
      this.$api.deleteAct(this.data.id, this.value).then((res) => {
        if (res.status === 200) {
          Toast.success('报名已取消')
          this.status = 0
          this.tt = '报 名'
          this.ttdis = false
          this.$api.getUserCount(this.data.id, this.value).then((res) => {
            if (res.status === 200) {
              this.count = res.data.count
            }
          })
        }
      })
    },
    save () {
      if (this.value === '') {
        Toast.fail('没有选择活动地点')
        return
      }

      const obj = {
        activity_id: this.data.id,
        activity_address_id: this.value,
      }
      this.$api.ActYuyue(obj).then((res) => {
        if (res.status === 200) {
          Toast.success('报名成功')
          this.status = 1
          if (this.status === 1) {
            this.tt = '已报名'
            this.ttdis = true
            const url = this.$u.getUrlPrefix() + '/f'
            this.$nextTick(function () {
              document.getElementById('qrcode').innerHTML = ''
              const qrcode = new QRCode('qrcode', {
                width: 150,
                height: 150,
                text: url,
              })
              console.log(qrcode)
            })
            this.url = url
          }

          this.$api.getUserCount(this.data.id, this.value).then((res) => {
            if (res.status === 200) {
              this.count = res.data.count
            }
          })
          // this.data = res.data.data;
        }
      })
    },
    onSubmit () {},
    getAddress (id) {
      this.data2.forEach((item, index) => {
        if (item.id === id) {
          this.onConfirm(item)
        }
      })
    },
    getAct (id) {
      this.$api.getActivity(id).then((res) => {
        if (res.status === 200) {
          this.data = res.data.data
        }
      })
      this.$api.getActivityAddresss(id).then((res) => {
        if (res.status === 200) {
          this.data2 = res.data.data
          this.$api.getExt(id).then((res) => {
            if (res.status === 200) {
              this.status = res.data.status
              const d = res.data.data
              if (d !== undefined) {
                this.getAddress(d.activity_address_id)
              }
              if (this.status === 1) {
                this.tt = '已报名'
                this.ttdis = true
                const url = this.$u.getUrlPrefix() + '/f'
                this.$nextTick(function () {
                  document.getElementById('qrcode').innerHTML = ''
                  const qrcode = new QRCode('qrcode', {
                    width: 150,
                    height: 150,
                    text: url,
                  })
                  console.log(qrcode)
                })
                this.url = url
                console.log(this.url)
              }
            }
          })
        }
      })
    },
    onConfirm (value) {
      this.value = value.id
      this.title = value.name
      this.address = value.address
      this.sumCount = value.count

      this.$api.getUserCount(this.data.id, this.value).then((res) => {
        if (res.status === 200) {
          this.count = res.data.count
        }
      })
      this.showPicker = false
    },
    onClickLeft () {
      this.$router.back()
    },
    getUserTeams () {
      this.$api.getUserTeams().then((res) => {
        if (res.status === 200) {
          this.teams = res.data
          this.teams.forEach((item, index) => {
            if (item.id === this.team.id) {
              this.defaultIndex = index
            }
          })
        }
      })
    },
    changeTeam (team) {
      this.$api.changeTeam({ team_id: team.id }).then((res) => {
        if (res.status === 200) {
          const token = res.data.token
          const user = res.data.user
          const team = res.data.default_team

          this.$s.commit('setToken', token)
          this.$s.commit('setProfile', user)
          this.$s.commit('setTeam', team)

          this.showPicker = false
          // this.$router.push({ name: "home" });
        }
      })
    },
    // 获取列表
    onSelect (action) {
      // Toast(action.value);
      if (action.value === '1') {
        this.showPicker = true
      } else if (action.value === '2') {
        // this.showPicker = true;
        this.signOut()
      }
    },
    showAddAppDialog () {
      this.addAppObj = {
        formId: '',
        formName: '',
        formDesc: '',
        formPicture: 'app1.png',
      }
      this.addAppDialog = true
    },

    showEditAppDialog (item) {
      this.$set(this.addAppObj, 'formId', item.id)
      this.$set(this.addAppObj, 'formName', item.name)
      this.$set(this.addAppObj, 'formDesc', item.desc)
      this.$set(this.addAppObj, 'formPicture', item.picture)
      this.addAppDialog = true
    },
    showAddFormDialog () {
      this.addFormObj = {
        formId: '',
        formName: '',
        formDesc: '',
        formType: 'T01',
        formAppID: '',
        formPicture: 'form1.png',
      }
      this.addFormDialog = true
    },

    showEditFormDialog (item) {
      this.$set(this.addFormObj, 'formId', item.id)
      this.$set(this.addFormObj, 'formName', item.name)
      this.$set(this.addFormObj, 'formDesc', item.desc)
      this.$set(this.addFormObj, 'formPicture', item.picture)
      this.$set(this.addFormObj, 'formType', item.type)
      this.$set(this.addFormObj, 'formAppID', item.app_id)
      this.addFormDialog = true
    },
    getStarSchema () {
      this.stars = []
      this.$api.getStarForm().then((res) => {
        if (res.status === 200) {
          // this.stars = res.data
          // this.$api.getGroupByPermission().then((res3) => {
          //  if (res3.status === 200) {
          //    res.data.forEach((item) => {
          //      res3.data.forEach((item3) => {
          //        if (item.id === item3.schema_id) {
          //          if (item3.read === 1) {
          //            this.stars.push(item)
          //          }
          //        }
          //      })
          //    })
          //  }
          // })
        }
      })
    },
    star (n, v) {
      // console.log(n);
      n.star = v
      const dataObj = {
        id: n.id,
        star: v,
      }

      this.$api.postStarForm(dataObj).then((res) => {
        if (res.status === 200) {
          this.getStarSchema()
          this.initData()
        }
      })
    },
    addAppImg (v) {
      this.addAppObj.formPicture = v
      this.addAppImageDialog = false
    },
    addFormImg (v) {
      this.addFormObj.formPicture = v
      this.addFormImageDialog = false
    },
    initData () {
      // this.$api.postFormItemReport().then((res) => {
      //  // this.count =
      //  console.log(res.data)
      // })

      // this.$api.postFormItemReportCount().then((res) => {
      //  if (res.status === 200) {
      //    this.count = res.data.count
      //  }
      // })
      this.getStarSchema()
      this.$api.getAppsSchemas({}).then((res) => {
        if (res.status === 200) {
          this.apps = res.data
          console.log(this.apps)
        }
      })
    },

    getListData () {
      this.$api.getForm().then((res) => {
        this.dataList = res.data
      })
    },
    // 编辑表单
    editItem (item, index) {
      if (item.type === 'T03') {
        this.$router.push({
          name: 'form_report_list',
          params: {
            schema_id: item.id,
            app_id: item.app_id,
          },
        })
      } else {
        this.$router.push({
          name: 'form_list',
          params: {
            schema_id: item.id,
            app_id: item.app_id,
          },
        })
      }
    },
    // 弹窗显示
    showDeleteAppDialog (item) {
      this.deleteText = ''
      this.deleteAppObj = item
      this.deleteAppDialog = true
    },

    // 删除表单
    deleteForm () {
      this.$api
        .deleteForm(this.deleteFormObj.id)
        .then((res) => {
          //      this.getListData();
          this.initData()
          this.deleteFormDialog = false
          this.$message({ message: '删除成功', type: 'success' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toDetail (item) {
      this.$router.push({
        name: 'form_design',
        params: {
          id: item.id,
        },
      })
    },
    toDetail2 (item) {
      this.$router.push({
        name: 'form_report',
        params: {
          id: item.id,
          type: 'report',
        },
      })
    },
    openApp () {
      this.$router.push({
        name: 'apps',
      })
    },
    addForm () {
      if (!this.addFormObj.formName || this.addFormObj.formName === '') {
        this.$message({ message: '名称必须填写', type: 'warning' })
        return
      }

      if (!this.addFormObj.formAppID || this.addFormObj.formAppID === '') {
        this.$message({ message: '应用必须选择', type: 'warning' })
        return
      }
      if (this.addFormObj.formId === '') {
        const dataObj = {
          name: this.addFormObj.formName,
          desc: this.addFormObj.formDesc,
          picture: this.addFormObj.formPicture,
          numberId: '',
          extend: [],
          kind: 'kind',
          app_id: this.addFormObj.formAppID,
          config: {
            name: this.addFormObj.formName,
            desc: this.addFormObj.formDesc,
            numberId: '',
            columns: [],
          },
          type: this.addFormObj.formType,
        }

        if (this.addFormObj.formType === 'T03') {
          this.$api.postForm(dataObj).then((res) => {
            if (res.status === 200) {
              // this.$message({ message: '创建成功', type: 'success' })
              const schemaID = res.data.id
              this.$router.push({
                name: 'form_report',
                params: { id: schemaID },
              })
            }
          })
        } else {
          this.$api.postForm(dataObj).then((res) => {
            if (res.status === 200) {
              // this.$message({ message: '创建成功', type: 'success' })
              const schemaID = res.data.id
              this.$router.push({
                name: 'form_design',
                params: { id: schemaID },
              })
            }
          })
        }
      } else {
        const dataObj = {
          id: this.addFormObj.formId,
          name: this.addFormObj.formName,
          desc: this.addFormObj.formDesc,
          picture: this.addFormObj.formPicture,
        }

        this.$api
          .putInfoForm(
            this.addFormObj.formId,
            dataObj,
            this.addFormObj.formAppID
          )
          .then((res) => {
            if (res.status === 200) {
              // const schemaID = res.data.id
              // this.$router.push({
              //  name: "form_report",
              //  params: { id: schemaID },
              // });
              this.addFormDialog = false
              this.initData()
              this.$message({ message: '修改表单成功', type: 'success' })
            }
          })
      }
    },
    addApp () {
      if (!this.addAppObj.formName || this.addAppObj.formName === '') {
        this.$message({ message: '名称必须填写', type: 'warning' })
        return
      }
      if (this.addAppObj.formId === '') {
        const dataObj = {
          name: this.addAppObj.formName,
          desc: this.addAppObj.formDesc,
          picture: this.addAppObj.formPicture,
          numberId: '',
          extend: [],
          kind: 'kind',
          config: {
            name: this.addAppObj.formName,
            desc: this.addAppObj.formDesc,
            numberId: '',
            columns: [],
          },
        }
        this.$api.postApp(dataObj).then((res) => {
          if (res.status === 200) {
            this.addAppDialog = false

            this.initData()
            this.$message({ message: '创建成功', type: 'success' })
          }
        })
      } else {
        const dataObj = {
          id: this.addAppObj.formId,
          name: this.addAppObj.formName,
          desc: this.addAppObj.formDesc,
          picture: this.addAppObj.formPicture,
          numberId: '',
          extend: [],
          kind: 'kind',
          config: {
            name: this.addAppObj.formName,
            desc: this.addAppObj.formDesc,
            numberId: '',
            columns: [],
          },
        }

        this.$api.putApp(dataObj).then((res) => {
          if (res.status === 200) {
            this.addAppDialog = false

            this.initData()
            this.$message({ message: '修改成功', type: 'success' })
          }
        })
      }
    },

    showDeleteFormDialog (item) {
      this.deleteFormObj = item
      this.deleteFormDialog = true
    },

    // 删除表单
    deleteApp () {
      this.$api
        .deleteApps(this.deleteAppObj.id)
        .then((res) => {
          //      this.getListData();
          this.initData()
          this.deleteAppDialog = false
          this.$message({ message: '删除成功', type: 'success' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toSetting (schema) {
      this.showEditFormDialog(schema)
      // if (schema.type !== "T03") {
      //  this.$router.push({ name: "form_design", params: { id: schema.id } });
      // } else if (schema.type === "T03") {
      //  this.$router.push({
      //    name: "form_report",
      //    params: { id: schema.id, type: "report" },
      //  });
      // }
    },
    toDesign (schema) {
      if (schema.type !== 'T03') {
        this.$router.push({ name: 'form_design', params: { id: schema.id } })
      } else if (schema.type === 'T03') {
        this.$router.push({
          name: 'form_report',
          params: { id: schema.id, type: 'report' },
        })
      }
    },
    clickApp (app) {
      console.log('clickApp', app)
      this.$router.push({ name: 'app', params: { app_id: app.id } })
    },
    book () {
      window.open('https://www.yuque.com/kuaidayun/help', '_blank')
    },
    signOut () {
      this.$s.commit('removeToken')
      this.$s.commit('removeProfile')
      this.$s.commit('removeTeam')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile_setting {
  .home-main {
    height: 100%;
    margin: 0 auto;
    .home-main-left {
      display: inline-block;
      width: 250px;
      height: 100px;
      .center {
        height: 100%;
        width: 100%;
        font-size: 24px;
        font-weight: 500;
        align-items: center;
        text-align: center;
      }
    }
    .home-main-right {
      float: right;
      width: calc(100% - 300px);
      height: 100px;
      background: #fff;
      padding: 0 20px;
      border-radius: 8px;
      ul {
        height: 100%;
        width: 100%;
        list-style: none;
        display: inline-flex;
        li {
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: 500;
          width: 33%;
          height: 100%;
          display: inline-flex;
        }
      }
    }
  }

  .container {
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 960px) {
    .container {
      max-width: 900px;
    }
  }
  @media (min-width: 1264px) {
    .container {
      max-width: 1185px;
    }
  }
  @media (min-width: 1904px) {
    .container {
      max-width: 1785px;
    }
  }
  .container--fluid {
    max-width: 100%;
  }
  .app-card {
    width: 235px;
    height: 128px;
    background: #fff;
    margin: 0 16px 16px 0;
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgb(12 31 80 / 8%);
    cursor: pointer;
    transition: box-shadow 0.15s ease-out 0s;
  }
  .app-card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .action {
    float: right;
    width: 25%;
    margin: 4px 0;
    text-align: center;
    color: #acb3bd;
    outline: none;
    transition: all 0.3s ease-in-out;
    .el-button {
      padding: 0px;
    }
  }
  .index-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    .user-info {
      display: flex;
      padding: 24px 16px 0;
      width: 100%;
      height: 140px;
      box-sizing: border-box;
      //margin-bottom: -45px;
      margin-bottom: 20px;
      background-color: #fff;
      .layout {
        width: calc(100% - 84px);
        box-sizing: border-box;
        float: left;
        .nickname {
          color: #333;
          font-size: 20px;
          line-height: 28px;
          height: 28px;
          font-weight: 700;
        }
        .tip {
          font-size: 12px;
          line-height: 20px;
          height: 16px;
          color: #666;
        }
      }
    }
    .content {
      flex: 1 1 auto;
      display: block;
      position: relative;
      padding-bottom: 20px;
      overflow: hidden;
      box-sizing: border-box;
      margin-left: 16px;
      margin-right: 16px;
      .card {
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
        box-sizing: border-box;
        padding: 16px;
        border: 1px solid transparent;
        border-bottom: 0;
      }
    }
  }
}
.input {
  width: 80%;
  /* float: left; */
  margin-left: 5%;
  height: 37px;
  line-height: 37px;
  border: 0px;
  color: #333333;
  font-size: 16px;
  background-color: #efefef;
}
.content {
  width: 100%;
}
</style>
