<template lang="pug">
.user
  router-view
</template>

<script>
export default {
  name: 'user',
}
</script>

<style lang="stylus" scoped></style>
