<template lang="pug">
.app
  van-nav-bar(
    title="活动列表"
    @click-left="onClickLeft"
    left-text="返回"
    left-arrow)
  div
    div(class="input" style="background-color: #FFFFFF;display: flex;align-items: center;justify-content: space-around;font-size: 16px;    box-sizing: border-box;box-shadow: 0 0.5em 2em -0.7em rgb(64 90 163 / 15%);" id='ln')
      van-radio-group(v-model="radio" direction="horizontal")
        van-radio(name="1") 中 文
        van-radio(name="2") English
    div(class="content" style="width: 100%;")
      el-row(style="margin-top:30px")
        el-col(:span="24" v-for="(d, index) in data" :key="d.id")
          el-card(style="margin-bottom:30px;")
            div(v-on:click="toInfo(d.id)")
              img(:src="require('@/assets/ww/' + d.url)" class="image")
              //span {{ d.url}}
              div
                span(style="font-weight: 600; font-size: 20px;") {{ d.name }}
                br
                span(style="font-weight: 400; font-size: 17px;") 活动类型: {{ d.type }}
                br
                span(style="font-weight: 400; font-size: 17px;") 时间: {{ $u.formatToDate(d.time) }}

</template>

<script>
export default {
  data: () => ({
    radio: "1",
    data: [],
  }),
  computed: {
    team() {
      return this.$s.getters.team;
    },
    profile() {
      return this.$s.getters.profile;
    },
    role() {
      return this.$s.getters.profile.role[0].role;
    },
  },
  watch: {
    $route: function (to, from) {
      console.log("change route");
      this.initData();
    },
    team: function (team) {
      console.log("change team to", team.name);
      this.initData();
    },
  },
  mounted() {
    console.log("index mounted");
  },
  created() {
    this.doSearch();
  },
  methods: {
    doSearch() {
      this.$api.getActivitys().then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          this.data = res.data.data;
        }
      });
    },
    onClickLeft() {
      this.$router.back();
    },
    toInfo(id) {
      this.$router.push({ name: "activity_info", params: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  height: 37px;
  line-height: 37px;
  border: 0px;
  color: #333333;
  font-size: 16px;
  background-color: #efefef;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
