<template lang="pug">
.layout
  .layout_wrapper
    router-view

    van-tabbar(route)
      van-tabbar-item(icon="home-o" name="home" :to="{ name: 'home'}" ) 活动
      van-tabbar-item(icon="setting-o" name="setting"  :to="{ name: 'user' }") 我的
</template>

<script>
export default {
  props: {
    source: String,
  },
  data: () => ({
    xapp: '',
    active: '',
    addObj: {
      formName: '',
      fromDesc: '',
      formType: '',
    },
    addObj2: {
      formName: '',
      fromDesc: '',
    },
    teams: [],
  }),
  computed: {
    team () {
      return this.$s.getters.team
    },
    profile () {
      return this.$s.getters.profile
    },
    role () {
      return this.$s.getters.profile.role[0].role
    },
  },
  watch: {
    team: function (team) {
      console.log('change team to', team.name)
    },
  },
  created () {},
  methods: {
    getUserTeams () {
      this.$api.getUserTeams().then((res) => {
        if (res.status === 200) {
          this.teams = res.data
        }
      })
    },
    changeTeam (name, id) {
      this.$api.changeTeam({ team_id: id }).then((res) => {
        if (res.status === 200) {
          const token = res.data.token
          const user = res.data.user
          const team = res.data.default_team

          this.$s.commit('setToken', token)
          this.$s.commit('setProfile', user)
          this.$s.commit('setTeam', team)

          this.$router.push({ name: 'home' })
        }
      })
    },
    book () {
      window.open('https://www.yuque.com/kuaidayun/help', '_blank')
    },
    signOut () {
      this.$s.commit('removeToken')
      this.$s.commit('removeProfile')
      this.$s.commit('removeTeam')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  .kuai-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    z-index: 100;
    color: #fff;
    font-size: 13px;
    box-shadow: 0 4px 6px 0 rgb(12 31 80 / 4%);
    background: #fff;
    .app-edit-left-nav {
      padding: 0px;
      justify-content: flex-start;
      .app-title {
        margin-right: 5px;
        max-width: calc(50vw - 450px);
        font-size: 20px;
        font-weight: 500;
        color: #494f57;
      }
    }
    .center-nav {
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 535px;
      li {
        font-size: 20px;
        font-weight: 500;
        height: 100%;
        a {
          position: relative;
          text-decoration: none;
          color: #aaaeb3;
          height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0 19px;
        }
        a.active {
          color: #121315;
        }
        a.active:before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 50%;
          margin-left: -18px;
          width: 36px;
          height: 4px;
          background: #fb9337;
        }
      }
    }
    .nav-tool {
      line-height: 45px;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
      li {
        display: inline-flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        color: #fff;
        font-size: 16px;
        outline: none;
        .help {
          padding: 0 8px;
          .el-button--text {
            color: #acb3bd;
          }
        }
      }
    }
    .app-edit-left-nav {
      line-height: 45px;
      height: 100%;
      display: inline-flex;
      align-items: center;
      list-style: none;
    }
  }
  .content {
    height: calc(100% - 60px);
  }
}
.wrapper {
  height: 100%;
  box-sizing: border-box;
  background-color: #f7f7f7;
  .index-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    .user-info {
      display: flex;
      padding: 24px 16px 0;
      width: 100%;
      height: 140px;
      box-sizing: border-box;
      //margin-bottom: -45px;
      margin-bottom: 20px;
      background-color: #fff;
      .layout {
        width: calc(100% - 84px);
        box-sizing: border-box;
        float: left;
        .nickname {
          color: #333;
          font-size: 20px;
          line-height: 28px;
          height: 28px;
          font-weight: 700;
        }
        .tip {
          font-size: 12px;
          line-height: 20px;
          height: 16px;
          color: #666;
        }
      }
    }
    .content {
      flex: 1 1 auto;
      display: block;
      position: relative;
      padding-bottom: 20px;
      overflow: hidden;
      box-sizing: border-box;
      margin-left: 16px;
      margin-right: 16px;
      .card {
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
        box-sizing: border-box;
        padding: 16px;
        border: 1px solid transparent;
        border-bottom: 0;
      }
    }
  }
}
</style>
