<template lang="pug">
.site
  .header
    .content.w100p.h100p.d-flex.align-center.justify-space-between
      .logo
        img(src="@/assets/logo_mini.png" style="vertical-align: middle;width: 2.1em;")
        span(style="color: #333; font-weight: bold; font-size: 1.6em;vertical-align: text-top;") Kuaida
      .menu.d-flex
        .div 产品
        .div 解决方案
        .div 价格
        .div 服务支持
      .right.d-flex
        el-button 登录
        el-button(type="primary") 注册
  .dvContent.d-flex
    .dvLeft.d-flex.justify-center.align-center
      .introduce
        h1(style="font-weight: 500;font-size: 2em;letter-spacing: 0.2rem;") 让企业管理更简单
        p(style="margin-top: 0;font-size: 1em;color: #666;text-indent: 2em;")
          | Kuaida ......
        div
          el-input(v-model="sMobileNo" placeholder="输入您的手机号" size="mini" style="width: 100%;")
          el-button(type="primary" size="mini" style="width: 40%;margin-top: 0.5em;" @click="goRegister") 免费注册
    .dvright.d-flex.justify-center.align-center
      img(src="@/assets/site/home_top.png" style="height: auto;max-width: 100%;")
  .dvContent(style="background: #fff;")
    h2#product.hTitle 产品与对应服务
    div(style="margin-top: 2em;")
      .productItem(v-for="(item,index) in slProductList" :key="item.sName+item.sTitle")
        .productTitle
          div(style="padding-left: 3em;text-align: left;position: relative;")
            .dvTriangleUp(:style="'border-bottom: 0.8em solid #'+item.iconColor")
            div
              img(:src="'/static/images/exp/img/icon_'+item.icon+'_'+item.iconColor+'_48.png'" style="width: 2em;")
              span(style="display: inline-block;font-weight: 500;font-size: 1.5em;vertical-align: text-top;")
                | {{item.sTitle}}
            div(style="font-weight: 500;font-style: normal;font-size: 1.2em;color: #333;")
              | {{item.sContent}}
        .productPic
          img(:src="item.sImageUrl" style="width: 100%;")
  .dvContent
    h2#solution.hTitle(style="padding-left: 1em;padding-right: 1em;") 解决方案·架构
    div(style="font-weight: 400;font-style: normal;font-size: 1em;color: #999999;padding-left: 1em;padding-right: 1em;")
      | DORADO以客户为中心,实现三位一体（横向、纵向、财务业务一体）大协同
    div(style="background: #fff;border-radius: 3px;margin-top: 2em;")
      img(src="/static/images/exp/image/new_plan_img.png" style="height:auto;max-width: 95%;")
  .dvContent
    h2#price.hTitle 灵活的购买方案，满足您的多种需求
    div(style="font-weight: 400;font-style: normal;font-size: 1em;color: #999999;text-align: center;")
      div 保证品质和服务，承担责任和承诺
      div 免费咨询顾问：400-659-9879
    div(style="margin-top: 4em;white-space: nowrap;overflow-y: auto;")
      // div(style="display: inline-block;width: 50%;" v-for="(item, index) in merits" :key="item.sCommodityName")
      //   .dvBoxShadow.dvMerits
      //     .dvMeritsTitle {{item.sGroupName}}
      //     p.dvP(style="font-weight: 400;font-style: normal;font-size: 1em;color: #999999; margin:1.3em 1.5em 0 1.5em") {{item.sGroupNo}}
      //     .dvMeritsContent
      //       div
      //         span(style="font-size:40px") {{item.nPriceMonth}}
      //         span 元/月起
      //       div
      //         div(@click="getDtl(item.uGuid)") 商品详情
      //         .content(:bordered="false" slot="content")
      //           // p(v-for="(item,index) in commodityList" :key="item.uGuid") {{index+1}}、{{item.sMessage}}
      //       el-button(type="primary" style="width: 100%;margin-top:10px" @click="learnMore") 了解更多
      //       div(style="font-style: normal;font-size: 1em;color: #999999;word-wrap: break-word;white-space: initial;margin-top: 0.5em;")
      //         | 现在购买后续还可以享受更多优惠
      template(v-if="merits.length===0")
        div(style="display: inline-block;width: 50%;" v-for="item in 2-merits.length" :key="item")
          .dvBoxShadow.dvMerits
            .coming-soon
              div(style="font-size:26px;") 敬请期待
  .dvContent(style="background: #fff;margin-top: 2em;padding-bottom: 3em;padding-top: 3em;position: relative;")
    div(style="position: absolute;width: 100%;left: 0;")
      h2(style="padding-top: 0px;font-weight: 500;margin-bottom:5px;font-size: 2em;text-align: center;") 18年专业领域深耕
      .dvFlex(style="margin-top: 1em;")
        .dvSuccess
          .spTitle 纺织企业案例
          .spContent 3,000+
        .dvSuccess
          .spTitle 纺织大中型企业
          .spContent 200+
        .dvSuccess
          .spTitle 纺织企业500强
          .spContent 100+
        .dvSuccess
          .spTitle 纺织上市公司
          .spContent 40+
    img(src="/static/images/exp/image/major_bg_img.png" style="width: 100%;height: auto;")
  .dvContent
    h2.hTitle 无数团队和组织共同的选择
    div(style="background: #fff;border-radius: 3px;margin-top: 2em;padding: 2em;")
      .dvFlex.dvBusiness
        div(style="flex: 1;" v-for="index of 5")
          img(:src="'/static/images/exp/image/business/business_'+index+'.jpg'")
      .dvFlex.dvBusiness
        div(style="flex: 1;" v-for="mIndex of 5")
          img(:src="'/static/images/exp/image/business/business_2_'+mIndex+'.jpg'")
  .dvContent(style="margin-top: 4em;background: #fff;")
    h2.hTitle 环融思远·共创未来
    div(style="margin-top: 10px;padding-bottom: 48px;")
      el-input(v-model="sMobileNo" placeholder="输入您的手机号" size="mini" style="width: 100%;")
      el-button(type="primary" size="mini" style="width: 40%;margin-top: 0.5em;" @click="goRegister") 免费注册
  div(style="text-align: center;")
    .dvFooter
      span.spFooter 博客
      span.spFooter 关于我们
      span.spFooter
        img(src="@/assets/logo_mini.png" style="vertical-align: middle;")
      span.spFooter 服务条款
      span.spFooter 隐私声明
    div(class="home_footer")
      | © 2021 kuaidayun.cn
</template>

<script>
export default {
  name: 'site',
  data () {
    return {
      sMobileNo: '',
      iNowIndex: 0,
      user: JSON.parse(sessionStorage.getItem('loginData')),
      slProductList: [
        {
          sName: 'OMA',
          sTitle: '供应链管理系统',
          sContent: '整合和优化企业内部各环节的流程和数据、形成企业对外竞争优势',
          sDesc: '提供多种MRP逻辑，MTO，MTS(按CC+季，按供应平衡等)；<br>' +
            '提供与客人多渠道、多信息的沟通跟踪；<br>' +
            '根据快反生产的情况，智能反应订单的执行进度<br>' +
            '提供目视化的管理报表，预警提示等，提高工作效率；<br>' +
            '通过系统做到事先计划，事中控制，事后总结<br>' +
            '<br><br>' +
            '1、实现订单生命周期全面管控，提高效益；<br>' +
            '2、真实准确的反映运营成本状况，服务于企业经营策略;<br>' +
            '3、 实现操作过程目视管理，达到工序一体化、作业均衡化和操作协同化。<br>',
          sImageUrl: '/static/images/exp/image/scm_img.png',
          icon: '01',
          iconColor: '1296db'
        },
        {
          sName: 'WHM',
          sTitle: '仓储管理',
          sContent: '提升仓库作业效率，加快收货与发货速度',
          sDesc: 'WMS的主要功能是对仓库内所有商品的运转生态进行全面的追踪，连接不同的管理系统，让仓管人员随时随地了解仓库当下最新库存数据。' +
            '<br><br>' +
            '1、货位优化，提高分拣作业效率；' +
            '<br>' +
            '2、盘点多级化，提高盘点的准确率和作业效率；<br>' +
            '3、仓储作业信息化，提高仓储作业效率；<br>' +
            '4、操作实现程序化，提高出入库作业效率；<br>' +
            '5、系统无缝对接，包括采购、生产、外协、销售等；<br>',
          sImageUrl: '/static/images/exp/image/wms_img.png',
          icon: '05',
          iconColor: '8676ef'
        },
        {
          sName: 'CPS',
          sTitle: '计划系统',
          sContent: '智能排产，计划与执行链接',
          sDesc: '更具企业历史销售信息、产品趋势或客户提供的需求预测而做的对外部需求的预测；合理规划什么时间需要什么产品，价格多少，采用何种运输方式等。<br>' +
            '<br><br>',
          sImageUrl: '/static/images/exp/image/new_cps_img.png',
          icon: '03',
          iconColor: '76cf9c'
        },
        {
          sName: 'MES',
          sTitle: '生产执行系统',
          sContent: '学决策、降低生产经营风险',
          sDesc: '对海量的工业数据进行分析和处理，模拟“人脑”进行思考并反应，帮助生产管理人员进行科学决策、降低生产经营风险。<br>' +
            '<br><br>' +
            '1、帮助企业建立公司-工厂-车间-班组-工人多级计划管理模式；<br>' +
            '2、充分运用RFID物联网技术，实现对产品生产制造的全过程追溯；<br>' +
            '3、工艺库化，通过大工业数据分析，持续改进，减少对人脑经验的依赖。<br>',
          sImageUrl: '/static/images/exp/image/new_mes_img.png',
          icon: '07',
          iconColor: 'ee807c'
        },
        {
          sName: 'QCM',
          sTitle: '质量检验系统',
          sContent: '开发中',
          sDesc: '',
          sImageUrl: '/static/images/exp/image/mes_img.png',
          icon: '04',
          iconColor: 'ea8e5c'
        },
        {
          sName: 'COA',
          sTitle: '材料核算，成本核算',
          sContent: '精准无误的核算体系',
          sDesc: '保证进销存、生产和财务等企业活动都准确无误，保证财务的账面价值和仓库、生产线的库存价值一致。提供月加权平均、移动加权平均、先进先出等多种方法，以达到财务精准无误的目的。' +
            '<br><br>',
          sImageUrl: '/static/images/exp/image/co_img.png',
          icon: '02',
          iconColor: '1296db'
        },
        {
          sName: 'BI&Dashboard',
          sTitle: '数据分析',
          sContent: '研究对象内在规律',
          sDesc: '通过数据BI分析平台，实现进、销、存与财务的集成，及时汇总各业务层面的信息，形成总体信息，并能从各个方面、各个角度进行综合分析，辅助高层领导进行决策的系统。' +
            '<br><br>',
          sImageUrl: '/static/images/exp/image/new_bi_img.png',
          icon: '06',
          iconColor: '8676ef'
        }
      ],
      merits: [
        // {sTitle: '产品', sContent: [
        //     {sText: '使用期限不限', sImageUrl: '/static/images/exp/image/merits/icon_person.png'},
        //     {sText: '软件拓展性强', sImageUrl: '/static/images/exp/image/merits/icon_expand.png'},
        //     {sText: '架构配置灵活', sImageUrl: '/static/images/exp/image/merits/icon_expand.png'}
        //   ]},
        // {sTitle: '技术', sContent: [
        //     {sText: '数据储存加密', sImageUrl: '/static/images/exp/image/merits/icon_data_encry.png'},
        //     {sText: '软件硬件互通', sImageUrl: '/static/images/exp/image/merits/icon_app_am.png'},
        //     {sText: '编码体系完整', sImageUrl: '/static/images/exp/image/merits/icon_code.png'}
        //   ]},
        // {sTitle: '经验', sContent: [
        //     {sText: '行业经验丰富', sImageUrl: '/static/images/exp/image/merits/icon_trader.png'},
        //     {sText: '行业领先实力', sImageUrl: '/static/images/exp/image/merits/icon_stength.png'}
        //   ]},
        // {sTitle: '其他', sContent: [
        //     {sText: '云化更加安全', sImageUrl: '/static/images/exp/image/merits/icon_person.png'}
        //   ]}
      ],
    }
  },
  methods: {
    // 免费注册前往注册页面
    goRegister () {
      window.location.href = `/signup?iType=50&sMobileNo=${this.sMobileNo}`
    },
    learnMore () {
      if (!this.user) {
        window.location.href = '/signin?iType=0&commodity=1'
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="stylus">
.site
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  color: #333333;
  background: #fafbfd;
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;

  .header
    width: 100%;
    height: 4em;
    background: white;
    top: 0;
    left: 0;
    padding: 1em;
    box-sizing: border-box;
    box-shadow: 0 0.5em 2em -0.7em rgba(64,90,163,.15);
    .menu
      padding-top: 27px;
      position: absolute;
      left: 300px;
      a
        text-decoration: none;
        color:#333;
      a:hover, a:visited, a:link, {
        color:#333;
      }
      a:active{
        color:#348fe4
      }
</style>
